.brand__name {
  margin-top: 90px;

}

.brand__name img{
  width: 50px;
}

.brand__img{
  width: 80px;
  margin: auto;
}
.inEligible__box {
  border: 2px solid #181f2e ;
  margin: auto;
  max-width: 600px;
  padding: 20px 50px;
  border-radius: 10px;
  background-color: rgba(24, 40, 64, 0.8);
  font-family: 'Roboto', sans-serif;

}

ul li{
  font-size: 16px;
}
.inEligible__box  img{
  width: 100px;
  margin: auto;

}
.inEligible__box h1{
  font-size: 50px;
  font-weight: 900px;
}

section{
  max-width: 600px;
  margin: auto;
}


footer{
  margin: auto;
  max-width: 97%;
  margin-top: 100px;
  background: #181f2e;
  color: #fff;
  border: 1px solid #181f2e;
  border-radius: 10px;
  
}
.socials{
  display: flex;
  justify-content: center;
  margin: 20px;
}

.socials .social__item{
  margin: 0 20px;
  border: 1px solid #384560;
  border-radius: 10px;
  padding: 10px 20px;
  background-color: #384560;
  
  cursor: pointer;
}
.socials .social__item a{
  color: #e4dddd;
  text-decoration: none;
  font-size: 15px;
}
.warp{
  width: 20px;
  border-radius: 50%;
}


.socials .social__item #content{
  margin-left: 5px;
 }

 .eligible{
  border-radius: 50%;
 }

 .footer__write{
  max-width: 400px;
  text-align: start;
  margin-left: 85px;
  margin-top: -20px;
  font-size: 16px;
 }

@media  (max-width: 768px) {
  .brand__name {
    margin-top: 50px;
  }
  .inEligible__box {
    max-width: 100%;
    padding: 10px;
    
  }
  .inEligible__box h1{
    font-size: 30px;
    max-width: 600px;
    margin: auto;
    
  }
  ul li{
    font-size: 14px;
  }

  .socials .social__item #content{
   display: none;
  }
  
}
