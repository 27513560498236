/* src/Timeline.css */
.timeline {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    margin: 20px auto;
  }
  
  .timeline::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    background: rgb(138, 172, 160);
    color: #fff;
    z-index: -1;
  }
  
  .timeline-event {
    position: relative;
    margin: 0 20px;
  }
  
  .timeline-content {
    padding: 5px;
    background: rgb(60, 60, 90);
    border: 1px solid rgb(60, 60, 90);
    border-radius: 8px;
    min-width: 100px;
    height: 50px;
    text-align: center;
    color: #fff;
  }
  
  .timeline-date {
    font-weight: bold;
    display: block;
  }
  

  